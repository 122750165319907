import React, { ReactNode } from "react"
import { graphql } from "gatsby"
import { Box, SimpleGrid } from "@chakra-ui/core"
import GatsbyImage, { FluidObject } from "gatsby-image"
import * as R from "ramda"

import Heading from "../../components/typography/Heading"
import Body from "../../components/typography/Body"
import MaxWidthGrid from "../../components/Layout/MaxWidthGrid"
import RiderDescription from "../../components/RiderStyles/RiderDescription"
import BestBikes from "../../components/RiderStyles/BestBikes"

import { bp } from "../../utils/MediaQueries"
import { RiderStylesQuery } from "../../../graphql-types"
import BikeProductCard from "../../components/BikeProductCard"
import { placeholderImage } from "../../utils"
import { Button } from "../../components/Buttons"
import SEO from "../../components/boilerplate/seo"
import PageContainer from "../../components/PageContainer"

export const query = graphql`
  query RiderStyles {
    image1: file(relativePath: { eq: "rider-styles/rider-style-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "rider-styles/rider-style-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3: file(relativePath: { eq: "rider-styles/rider-style-3.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    casualCollection: contentfulCollection(
      internalTitle: { eq: "casual-rider-bike-recommendations" }
    ) {
      bikes {
        id
        title
        internalTitle
        speeds {
          internalTitle
          speed
          variants {
            title
            shopifyId
            color {
              internalTitle
              name
              hexCode
            }
            isNew
            forSale
            productListingImage {
              fluid(maxWidth: 600) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }

    dedicatedCollection: contentfulCollection(
      internalTitle: { eq: "dedicated-rider-bike-recommendations" }
    ) {
      bikes {
        id
        title
        internalTitle
        speeds {
          internalTitle
          speed
          variants {
            title
            shopifyId
            color {
              internalTitle
              name
              hexCode
            }
            isNew
            forSale
            productListingImage {
              fluid(maxWidth: 600) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }

    fanaticalCollection: contentfulCollection(
      internalTitle: { eq: "fanatical-rider-bike-recommendations" }
    ) {
      bikes {
        id
        title
        internalTitle
        speeds {
          internalTitle
          speed
          variants {
            title
            shopifyId
            color {
              internalTitle
              name
              hexCode
            }
            isNew
            forSale
            productListingImage {
              fluid(maxWidth: 600) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`

type RiderBlockProps = {
  index: number
  image: FluidObject
  riderStyle: "casual" | "dedicated" | "fanatical"
  riderDescription: ReactNode
  bestBikes: string[]
  collection: RiderStylesQuery["casualCollection"]
}

const RiderBlock = ({
  index,
  image,
  riderStyle,
  riderDescription,
  bestBikes,
  collection,
}: RiderBlockProps) => {
  const getRow = (row: number) => 4 * index - (3 - row - (index > 1 ? 1 : 0))
  const [collectionCount, setCollectionCount] = React.useState(2)

  return (
    <>
      <Box gridRow={getRow(0)} gridColumn={bp("1/end", "3/13")} mb="6.6875rem">
        <GatsbyImage fluid={image} backgroundColor="white" />
      </Box>
      <RiderDescription
        riderStyle={riderStyle}
        gridRow={getRow(1)}
        gridColumn={bp("1/end", "3/8")}
      >
        {riderDescription}
      </RiderDescription>
      <BestBikes
        items={bestBikes}
        gridRow={bp(getRow(2), getRow(1))}
        gridColumn={bp("1/end", "9/13")}
        mt={bp("3.25rem", "4.125rem")}
      />
      <SimpleGrid
        display={bp("none", "grid")}
        gridColumn={bp("1/end", "2/14")}
        gridRow={bp(getRow(3), getRow(2))}
        columns={[1, null, null, null, 2]}
        spacing="2rem"
        mt="2rem"
      >
        {collection?.bikes
          ?.map((bike) => {
            return (
              <BikeProductCard
                key={bike?.id}
                title={bike?.title ?? ""}
                to={`/products/${bike?.internalTitle}`}
                variants={R.flatten(
                  bike?.speeds?.map(
                    (speed) =>
                      speed?.variants?.map((variant) => {
                        return {
                          uniqueId: variant?.shopifyId || "",
                          productHandle: speed.internalTitle || "",
                          speed: speed.speed || "",
                          color: {
                            internalTitle: variant?.color?.internalTitle || "",
                            hexCode: variant?.color?.hexCode || "#FFF",
                            name: variant?.color?.name || "-",
                          },
                          isNew: variant?.isNew || false,
                          forSale: variant?.forSale || false,
                          image: (variant?.productListingImage?.fluid ||
                            placeholderImage) as FluidObject,
                        }
                      }) || []
                  ) || []
                )}
              />
            )
          })
          .slice(0, collectionCount)}
        {collectionCount <= (collection?.bikes?.length || 0) && (
          <Box gridColumn="1/-1" textAlign="center">
            <Button onClick={() => setCollectionCount(collectionCount + 2)}>
              View More
            </Button>
          </Box>
        )}
      </SimpleGrid>
      <Box
        mb="9.375rem"
        gridRow={bp(getRow(4), getRow(3))}
        gridColumn="1/end"
      />
    </>
  )
}

type RiderStylesProps = {
  data: RiderStylesQuery
  location: Location
}

export default function RiderStylesPage({ data, location }: RiderStylesProps) {
  return (
    <PageContainer>
      <SEO title="Rider Styles" location={location} />
      <Box textAlign="center" mb="80">
        <Heading mb="20">Rider Styles</Heading>
        <Body color="dawn">
          A quick overview of the different riding styles. Which one are you?
        </Body>
      </Box>
      <MaxWidthGrid>
        <RiderBlock
          index={1}
          image={data.image1?.childImageSharp?.fluid as FluidObject}
          riderStyle="casual"
          bestBikes={["Cruisers & Hybrids", "1 - 7 Speeds", "Comfort"]}
          riderDescription={
            <>
              You ride a few times a month and enjoy a spontaneous ride here and
              there. Your rides are less than ten miles and you&apos;re riding
              at a leisurely pace. You want to take in the scenery and feel the
              breeze in your face. You love having a bike for the sunny, warm
              days, so you can cruise around the neighborhood, park or by the
              beach. Your bike is a great way for you to get outside, maybe
              crack a little sweat or ride down to a local social spot. You plan
              a few rides here and there but most rides end up being on a whim.
              You need a bike that will be ready and reliable when you are ready
              to ride.
            </>
          }
          collection={data.casualCollection}
        />
        <RiderBlock
          index={2}
          image={data.image2?.childImageSharp?.fluid as FluidObject}
          riderStyle="dedicated"
          bestBikes={[
            "Hybrids & Cruisers",
            "1 - 7 Speeds",
            "Performance & Comfort",
          ]}
          collection={data.dedicatedCollection}
          riderDescription={
            <>
              You ride weekly. You love bike riding and are committed to getting
              in the saddle weekly. You generally keep your rides at ten miles
              or less but sometimes you&apos;ll push to close to twenty miles. A
              leisurely pace is generally your pace, but you&apos;ll push your
              pace to moderate on certain rides. You plan bike rides in advance
              to ensure you get on the bike and logs miles weekly. You love to
              have sun on your rides, but clouds or sun you want to be rolling
              on two wheels regardless. Rarely does a week go by without getting
              a little bike time. You need a bike that can perform for you
              weekly and get take you the distance you choose to ride.
            </>
          }
        />
        <RiderBlock
          index={3}
          image={data.image3?.childImageSharp?.fluid as FluidObject}
          riderStyle="fanatical"
          bestBikes={["High Quality", "1 - 21 Speeds", "Performance & Comfort"]}
          collection={data.fanaticalCollection}
          riderDescription={
            <>
              You ride daily and you crave being in the saddle. You love
              everything about bike riding and will choose a bike over other
              forms of transportation. You generally matinain a moderate riding
              pace but every now and you&apos;ll push to an aggressive pace.
              Rain, clouds, snow or sun you must find a way to get outside on
              your bike. Your rides can vary in distance but for you the more
              miles the better. Saddle time is one of the most important things
              in life and you plan rides with others to bond over you love of
              cycling. You ride your bike for any and all reason, exercise,
              transportation and fun. You need a bike that performs at the
              highest level no matter the circumstances, only the best quality
              bike for you.
            </>
          }
        />
      </MaxWidthGrid>
    </PageContainer>
  )
}
