import React from "react"
import { Box, BoxProps, Flex, Icon } from "@chakra-ui/core"
import Paragraph from "../typography/Paragraph"
import Heading from "../typography/Heading"

type RiderDescriptionProps = BoxProps & {
  riderStyle: "casual" | "dedicated" | "fanatical"
}

export default function RiderDescription({
  riderStyle,
  children,
  ...props
}: RiderDescriptionProps) {
  let title, icon
  switch (riderStyle) {
    case "casual":
      title = "Casual Riders"
      icon = "casualRider"
      break
    case "dedicated":
      title = "Dedicated Riders"
      icon = "dedicatedRider"
      break
    case "fanatical":
      title = "Fanatical Riders"
      icon = "fanaticalRider"
      break
  }
  return (
    <Box {...props}>
      <Flex mr="1.125rem" mb="0.8125rem">
        <Icon name={icon} size="3rem" mr="1.125rem" />
        <Heading as="h5" size="5" fontWeight="bold">
          {title}
        </Heading>
      </Flex>
      <Paragraph lineHeight="2rem">{children}</Paragraph>
    </Box>
  )
}
