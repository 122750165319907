import React from "react"
import { Box, BoxProps, Stack } from "@chakra-ui/core"
import SmallCaps from "../typography/SmallCaps"

type BestBikesProps = BoxProps & {
  items: string[]
}

const Item = (props: BoxProps) => (
  <Box
    px="1rem"
    py="0.6875rem"
    borderRadius="0.5rem"
    border="1px solid"
    borderColor="dividerLine"
    {...props}
  />
)

export default function BestBikes({ items, ...props }: BestBikesProps) {
  return (
    <Box {...props}>
      <SmallCaps size="sm" fontWeight="bold" mb="20">
        Best Bikes:
      </SmallCaps>
      <Stack spacing="1rem" isInline flexWrap="wrap">
        {items.map((item) => (
          <Item key={item} whiteSpace="nowrap" mb="1rem">
            {item}
          </Item>
        ))}
      </Stack>
    </Box>
  )
}
